<template>
  <div class="wx-event-handler-template">
    <div class="top-btns">
      <el-button
        type="primary"
        icon="el-icon-plus"
        @click="doAdd"
        size="mini"
        v-power="`9999-20-10`"
        >新增</el-button
      >
    </div>

    <!-- 搜索表单 -->
    <div class="search-form">
      <el-form inline size="mini">
        <el-form-item label="名称">
          <el-input v-model="searchForm.nameLike" clearable />
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="searchForm.descriptionLike" clearable />
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="searchForm.flag">
            <el-option label="有效" :value="1"></el-option>
            <el-option label="无效" :value="0"></el-option>
            <el-option label="删除" :value="-1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 数据列表 -->
    <common-table
      ref="temlplateDataTable"
      :columns="tableConfig.columns"
      :search-params="searchForm"
      :api-key="tableConfig.apiKey"
      :auto-load="tableConfig.autoLoad"
      :options="tableConfig.options"
    ></common-table>

    <!-- 编辑弹窗 -->
    <el-drawer
      :title="editDialogInfo.title"
      :visible.sync="editDialogInfo.show"
      :close-on-click-modal="false"
      destroy-on-close
      size="50%"
      @opened="handlerDialogOpend"
      @closed="handlerDialogClosed"
    >
      <template #title>
        <div style="width: 100%">
          <el-button
            type="primary"
            size="mini"
            :loading="editDialogInfo.saving"
            @click="saveTemplateData"
            >保存</el-button
          >
        </div>
      </template>

      <wx-event-handler-edit-dialog
        ref="editDialog"
        :action="editDialogInfo.action"
        :id="editDialogInfo.templateId"
      />
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "wx-event-handler-template",
  data() {
    return {
      editDialogInfo: {
        title: "新增模板",
        show: false,
        action: "add",
        templateId: null,
        saving: false,
      },
      searchForm: {},
      tableConfig: {
        apiKey: "fw-wx-event-template-list",
        autoLoad: true,
        options: [
          {
            label: "修改",
            powerId: "9999-20-20",
            handler: (row) => {
              this.editTemplate(row);
            },
          },
          {
            label: "停用",
            powerId: "9999-20-30",
            handler: (row) => {
              this.dropdownHandler({
                apiKey: "fw-wx-event-template-disable",
                op: "停用",
                opColor: "red",
                bean: row,
              });
            },
          },
          {
            label: "启用",
            powerId: "9999-20-40",
            handler: (row) => {
              this.dropdownHandler({
                apiKey: "fw-wx-event-template-enable",
                op: "启用",
                opColor: "green",
                bean: row,
              });
            },
          },
        ],
        columns: [
          {
            prop: "name",
            label: "名称",
          },
          {
            prop: "description",
            label: "描述",
          },
          {
            prop: "beanName",
            label: "Bean名称",
          },
          {
            prop: "_caption.flag",
            label: "状态",
          },
        ],
      },
    };
  },
  methods: {
    async dropdownHandler(cmd) {
      let _this = this;
      let { apiKey, op, bean, opColor } = cmd;
      this.$confirm(
        `将要<span style="color: ${opColor};">${op}</span>【${bean.name}】确信操作吗？`,
        "提示",
        {
          distinguishCancelAndClose: true,
          dangerouslyUseHTMLString: true,
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          async beforeClose(action, instance, done) {
            if (action === "confirm") {
              await _this.$http.doApi(apiKey, { id: cmd.bean.id });
              _this.$message.success("操作成功");
              _this.$refs.temlplateDataTable.refreshCurrent();
            }
            done();
          },
        }
      );
    },
    doAdd() {
      this.editDialogInfo.show = true;
    },
    editTemplate({ id }) {
      this.editDialogInfo.templateId = id;
      this.editDialogInfo.action = "edit";
      this.editDialogInfo.title = "修改模板";
      this.editDialogInfo.show = true;
    },
    handlerDialogOpend() {
      this.$refs.editDialog.opened();
    },
    handlerDialogClosed() {
      this.editDialogInfo.templateId = null;
      this.editDialogInfo.action = "add";
    },
    doSearch() {
      this.$refs.temlplateDataTable.refresh();
    },
    async saveTemplateData() {
      let data = await this.$refs.editDialog.getData();
      try {
        await this.$http.doApi("fw-wx-event-template-saveComb", {}, data);
        this.doSearch();
        this.editDialogInfo.show = false;
        this.$message.success("保存成功");
      } catch (e) {
        console.log(e);
      }
    },
  },
  components: {
    "wx-event-handler-edit-dialog": () =>
      import("./WxEventHandlerEditDialog.vue"),
  },
};
</script>

<style lang="less">
.wx-event-handler-template {
  .top-btns {
    margin: 1rem;
  }
  .search-form {
    margin: 1rem;
  }

  .template-edit-top-toolbar {
    margin-bottom: 1rem;
  }
}
</style>